#footer {
  background-color: var(--var-color-nm-blue);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-height: 900px) {
  #footer {
    margin-top: 10rem;
  }
}
