.faq-nav {
  height: 115px;
  padding: 1rem 1rem;
  box-shadow: 0px 10px 20px 0px rgba(1, 114, 163, 0.1);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.faq-nav-title {
  width: 100%;
  max-width: 385px;
}

.faq-nav-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 590px;
}

.faq-nav-links a {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;

  color: var(--var-color-black);
}

.faq-header {
  position: relative;
  padding: 2rem 0 1rem 0;
}

.faq-header:after {
  content: "";
  background: #d9d9d9;
  position: absolute;
  bottom: 0;
  left: -3%;
  height: 1px;
  width: 105%;
}

.faq-header p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.faq-header a {
  color: var(--var-color-black);
}

.faq-content {
  display: grid;
  gap: 1.5rem;
  margin-top: 2rem;
}

.faq-title p,
.faq-nav-title p {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.faq-title h5,
.faq-nav-title h5 {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-black);
}

.faq-txt {
  width: 100%;
}

.faq-txt small {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 130%;
  font-weight: 400;

  color: var(--var-color-black);
}
