header {
  border-bottom: 5px solid transparent;
  border-image: linear-gradient(
    0.25turn,
    rgba(12, 178, 235, 1),
    rgba(108, 194, 102, 1)
  );
  border-image-slice: 1;
  width: 100%;
  height: 200px;

  box-shadow: 0px 0px 50px 0px rgba(1, 114, 163, 0.15);
  padding-top: 2rem;
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid #808080;
  padding-bottom: 1rem;
}

.navbar-img {
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.navbar-img img {
  width: 60px;
  height: 55px;
  object-fit: contain;
}

#desk-img {
  display: none;
}

.navbar-links {
  display: flex;
  min-height: 100px;
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.1rem;
}

.navbar-sobre a,
.navbar-inscricoes a,
.navbar-comunidade a,
.navbar-faq a,
.navbar-cronograma p,
.navbar-regulamento p,
.navbar-provas p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 22px;

  color: var(--var-color-black);
}

.navbar-btn-estudante,
.navbar-btn-escola {
  scale: 0.85;
}

.navbar-btn-estudante a,
.navbar-btn-escola a {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;

  color: var(--var-color-black);
}

.navbar-btns {
  display: flex;
  flex-flow: row nowrap;
  padding: 0.2rem 0;
}

.navbar-btn-estudante {
  cursor: pointer;
  background-color: var(--var-color-nm-green);
  border-bottom: 5px solid var(--var-color-dk-green);
  box-shadow: 1px 6px 6px rgba(55, 136, 49, 0.2);
}

.navbar-btn-estudante:hover {
  background-color: var(--var-color-dk-green);
  border-bottom: 5px solid var(--var-color-nm-green);
}

.navbar-btn-estudante a:hover {
  color: var(--var-color-white);
}

.navbar-btn-estudante:active {
  background-color: var(--var-color-dk-green);
  border-bottom: 5px solid var(--var-color-dk-green);
}

.navbar-btn-estudante a:active {
  color: var(--var-color-white);
  font-weight: 600;
}

.navbar-btn-escola {
  cursor: pointer;
  background-color: var(--var-color-nm-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
  box-shadow: 1px 6px 6px rgba(1, 114, 163, 0.2);
}

.navbar-btn-escola:hover {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-nm-blue);
}

.navbar-btn-escola a:hover {
  color: var(--var-color-white);
}

.navbar-btn-escola:active {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
}

.navbar-btn-escola a:active {
  color: var(--var-color-white);
  font-weight: 600;
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 900px) {
  #mobile-img {
    display: none;
  }

  #desk-img {
    display: contents;
  }

  .navbar-img img {
    width: 100px;
  }

  .navbar-sobre a,
  .navbar-inscricoes a,
  .navbar-comunidade a,
  .navbar-faq a,
  .navbar-cronograma p,
  .navbar-regulamento p,
  .navbar-provas p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1300px) {
  .navbar-img img {
    width: 150px;
  }

  .navbar-btn-estudante a,
  .navbar-btn-escola a {
    font-size: 16px;
  }
}
