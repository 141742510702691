#duvidas {
  background-color: #fffbe8;
  padding-bottom: 2rem;
  background: linear-gradient(to top, #fffbe8 80%, #fff);
}

.duvidas-container {
  display: grid;
  justify-content: center;
  max-width: 500px;
}

.duvidas-txt {
  text-align: center;
  margin-bottom: 1rem;
}

.duvidas-txt h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 33px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.duvidas-txt p {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-black);
}

.accordion {
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 0.25rem;
}

.accordion-header {
  display: grid;
  grid-template-columns: 15fr 1fr;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  border-bottom: 1px solid var(--var-color-nm-blue);

  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.accordion-header.expanded {
  border-bottom: unset;
}

.accordion-content {
  background-color: #fffbe8;
  border-radius: 0 0 1rem 1rem;
  padding: 0.25rem 2rem;
}

.accordion-content p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.inside-txt {
  text-align: left;
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 600px) {
  #duvidas {
    padding: 3rem 0;
  }

  .accordion-header {
    width: 420px;
  }
}

@media screen and (min-width: 1200px) {
  #duvidas {
    padding: 4rem 0;
  }

  .accordion-header {
    width: 515px;
  }
}
