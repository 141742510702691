.conteudo-programatico-txt {
  margin-top: 2rem;
  max-width: 1080px;
  padding-bottom: 5rem;
}

.conteudo-programatico-txt h5 {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
  margin-bottom: 2rem;
}

.conteudo-programatico-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-black);
}

.conteudo-programatico-txt small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;

  color: var(--var-color-black);
}

#conteudo-programatico {
  padding-left: 1rem;
}

.lista-programatico {
  padding-left: 2rem;
}

.lista-programatico li {
  list-style-type: disc;
  margin-bottom: 0.2rem;
}
