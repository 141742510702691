.footer-container-mobile {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 2rem;
  max-width: 95%;
  width: 100%;
  height: 100%;
  padding: 1rem 0;
}

.footer-mobile-top {
  display: grid;
  justify-items: flex-start;
  width: 95%;
}

.footer-img-mobile {
  position: absolute;
  right: 0;
  top: -0.5rem;
}

.footer-img-mobile img {
  height: 150px;
  object-fit: contain;
}

.footer-mobile-mid {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.footer-links ul {
  width: 116%;
}

.footer-container-mobile h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-nm-yellow);
}

.footer-container-mobile small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  margin-top: 3px;
}

.footer-realizacao-mobile {
  display: grid;
  gap: 1rem;
  width: 20%;
}

.footer-realizacao-mobile h3 {
  width: 120px;
}

.footer-logos-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  width: 170px;
}

.footer-mail-mobile {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;

  color: var(--var-color-black);
}

.footer-logos-mobile img {
  height: 50px;
  object-fit: contain;
}

.footer-bebras-mobile,
.footer-conheca-mobile {
  display: grid;
  width: 50%;
}

.footer-mobile-bottom {
  display: flex;
  justify-content: space-between;
}

.footer-links li {
  padding: 0.2rem 0;
}

.footer-acompanhe-mobile {
  display: grid;
  width: 40%;
  height: 25px;
  justify-items: center;
}

.footer-icons {
  color: var(--var-color-black);
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.footer-icons a {
  text-decoration: none;
}

.copyright-container-mobile {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  position: relative;
  width: 100%;

  color: var(--var-color-black);
}

.copyright-container-mobile:before {
  content: "";
  background: var(--var-color-black);
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 95%;
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 600px) {
  .footer-mobile-top {
    width: 85%;
  }

  .footer-realizacao-mobile h3 {
    width: 300px;
  }

  .footer-img-mobile {
    top: -1rem;
  }

  .footer-img-mobile img {
    height: 300px;
  }

  .footer-logos-mobile {
    display: flex;
  }

  .footer-mobile-mid,
  .footer-mobile-bottom {
    width: 85%;
  }
}
