.comunidade-container {
  display: grid;
  justify-items: center;
  gap: 1rem;
}

.comunidade-txt {
  display: grid;
  justify-items: center;
  text-align: center;
  gap: 1rem;
}

.comunidade-txt h4 {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-nm-green);
}

.comunidade-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  max-width: 790px;
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 1024px) {
  .comunidade-img img {
    height: 450px;
    object-fit: contain;
  }
}
