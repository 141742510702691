.footer-container-web {
  position: relative;
  display: grid;
  gap: 1rem;
  max-width: 95%;
  width: 100%;
  height: 100%;
  padding: 2rem 0;
}

.footer-container-web h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-nm-yellow);
}

.footer-container-web small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  margin-top: 3px;
}

.footer-web-top {
  display: flex;
  width: 85%;
  justify-content: space-around;
  gap: 1rem;
}

.footer-img {
  position: absolute;
  right: 0;
  bottom: 0;
}

.footer-img img {
  height: 350px;
  object-fit: contain;
}

.footer-web-bottom {
  display: flex;
  justify-content: space-around;

  width: 95%;
}

.footer-realizacao {
  display: grid;
  gap: 0.5rem;
  width: 30%;
}

.footer-canguru,
.footer-conheca,
.footer-acompanhe {
  display: grid;
  width: 35%;
}

.footer-mail {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;

  color: var(--var-color-black);
}

.footer-mail small {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;

  color: var(--var-color-black);
}

.footer-logos {
  display: flex;
  width: 200px;
}

.footer-logos img {
  height: 50px;
  object-fit: contain;
}

.footer-links li {
  padding: 0.2rem 0;
}

.footer-icons {
  color: var(--var-color-white);
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.footer-icons a {
  text-decoration: none;
}

.copyright-container {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  position: relative;
  width: 100%;
  padding-top: 0.5rem;

  color: var(--var-color-black);

  margin: -1rem 0 0 1rem;
}

.copyright-container:before {
  content: "";
  background: var(--var-color-black);
  position: absolute;
  top: 0.5rem;
  left: 0;
  height: 1px;
  width: 72%;
}
