.card-personalizado {
  position: relative;
  background: var(--var-color-white);
  box-shadow: 0px 0px 50px 0px rgba(0, 157, 220, 0.15);
  border-radius: 25px;
  padding: 16px;
  position: relative;
  width: 365px;
  height: 645px;
}

.desconto {
  position: absolute;
  top: -5%;
  right: -20%;
  transform: translateX(-50%);
  z-index: 3;
}

.semi-circle-personalizado {
  background-color: var(--var-color-nm-green);
  width: 180px;
  height: 180px;
  position: absolute;
  top: 0px;
  left: calc(40% - 50px);
  clip-path: ellipse(35% 20% at 50% -5%);
}

.card-personalizado-content {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
}

.personalizado-top-txt {
  display: grid;
  justify-items: center;
  margin-top: 1rem;
}

.personalizado-top-txt h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;

  color: var(--var-color-nm-green);
}

.personalizado-top-txt small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.personalizado-top-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-nm-green);

  margin-top: 1rem;
  text-align: center;
}

.personalizado-mid-txt {
  display: grid;
  justify-content: start;
  gap: 0.5rem;
}

.personalizado-item1,
.personalizado-item2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.personalizado-item2 {
  position: relative;
}

.personalizado-item2 img {
  position: absolute;
  transform: translateX(-50%);
  z-index: 10;
}

.personalizado-item2 p {
  width: 309px;
  z-index: 1;
}

.img-personalizado1 {
  top: 50%;
  left: 61.5%;
  width: 220px;
  object-fit: contain;
}

.img-personalizado2 {
  top: 50%;
  left: 70%;
  width: 240px;
  object-fit: contain;
}

.img-personalizado3 {
  top: 50%;
  left: 70.5%;
  width: 260px;
  object-fit: contain;
}

.img-personalizado4 {
  top: 50%;
  left: 80.5%;
  width: 225px;
  object-fit: contain;
}

.img-personalizado5 {
  top: 50%;
  left: 66%;
  width: 240px;
  object-fit: contain;
}

.img-personalizado6 {
  top: 50%;
  left: 68.5%;
  width: 215px;
  object-fit: contain;
}

.personalizado-item2 span {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 130%;
  font-weight: 900;

  color: var(--var-color-nm-blue);

  background-color: #ffd200;
  border-radius: 5px;
  padding: 3px 5px;
}

.personalizado-mid-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;

  color: var(--var-color-black);
}

.personalizado-item0 {
  display: grid;
  align-items: center;
}

.personalizado-item0 p {
  font-size: 16px;
  color: var(--var-color-nm-green);
  font-weight: 800;
}

.item0-txt {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.personalizado-low-txt {
  margin-top: 2rem;
}

.valor-personalizado-riscado {
  align-self: flex-end;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  text-decoration: line-through;
}

.valor-personalizado-solo {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  max-width: 305px;
  padding-bottom: 0.25rem;
}

.valor-personalizado-solo small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  align-self: flex-end;
}

.destaque-valor-personalizado-solo {
  display: flex;
  justify-content: space-between;
  max-width: 305px;
}

.valor-personalizado-solo p {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-nm-green);
}

.valor-personalizado-desconto {
  align-self: center;

  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.boleto-valor-personalizado-solo {
  display: flex;
  justify-content: space-between;
  max-width: 305px;
  margin-top: 1rem;
}

.boleto-valor-personalizado-solo p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

#left-txt {
  max-width: 170px;
}

.btn-card-personalizado {
  cursor: pointer;
  background-color: var(--var-color-nm-green);
  border-bottom: 5px solid var(--var-color-dk-green);
  box-shadow: 1px 6px 6px rgba(1, 114, 163, 0.2);

  margin-top: 0.5rem;
  padding: 0.5rem 1.5rem !important;
  align-self: center;
}

.btn-card-personalizado a,
.btn-card-personalizado p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  color: var(--var-color-black);
}

.btn-card-personalizado:hover {
  background-color: var(--var-color-dk-green);
  border-bottom: 5px solid var(--var-color-nm-green);
}

.btn-card-personalizado a:hover {
  color: var(--var-color-white);
}

.btn-card-personalizado:active {
  background-color: var(--var-color-dk-green);
  border-bottom: 5px solid var(--var-color-dk-green);
}

.btn-card-personalizado a:active {
  color: var(--var-color-white);
  font-weight: 600;
}

.card-separador {
  display: flex;
  flex-flow: column;
  align-items: center;
}

#separador-web {
  display: none;
}

.separador p {
  font-family: "Open Sans", sans-serif;
  font-size: 90px;
  line-height: 126px;
  font-weight: 800;

  color: var(--var-color-purple);
}
