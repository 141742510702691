#comunidade-bottom {
  padding-bottom: 4rem;
}

.comunidade-container {
  display: grid;
  justify-items: center;
  gap: 1rem;
}

.comunidade-top-txt {
  display: grid;
  gap: 1rem;
  width: 95%;
  max-width: 700px;
  text-align: center;
}

.comunidade-top-txt h3 {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-nm-green);
}

.comunidade-top-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.comunidade-top-cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  gap: 1rem;
}

.comunidade-card {
  display: grid;
  gap: 1rem;
  justify-items: center;
  text-align: center;
}

.comunidade-card p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

#ampliar {
  width: 95%;
  max-width: 195px;
}

#tornar {
  width: 95%;
  max-width: 250px;
}

#aprimorar {
  width: 95%;
  max-width: 260px;
}

#estimular {
  width: 95%;
  max-width: 265px;
}

.comunidade-bottom-img {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  max-width: 800px;
}

#unv01,
#unv02,
#unv03 {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

#unv04 {
  width: 170px;
  height: 80px;
  object-fit: contain;
}

#unv05 {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

#unv06 {
  width: 200px;
  height: 60px;
  object-fit: contain;
}

.comunidade-bottom-txt {
  text-align: center;
  width: 95%;
  max-width: 855px;
}

.comunidade-bottom-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;

  color: var(--var-color-black);
}

.comunidade-btn {
  cursor: pointer;
  background-color: var(--var-color-nm-yellow);
  border: 2px solid var(--var-color-dk-yellow);
  box-shadow: 1px 6px 6px 0px rgba(55, 136, 49, 0.2);
}

.comunidade-btn a {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  color: var(--var-color-black);
}

.comunidade-btn:hover {
  background-color: var(--var-color-lt-yellow);
  border: 2px solid var(--var-color-dk-yellow);
  border-bottom: 5px solid var(--var-color-dk-yellow);
}

.comunidade-btn a:active {
  font-weight: 800;
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 768px) {
  .comunidade-mid-img img {
    height: 250px;
    object-fit: contain;
  }
}

@media screen and (min-width: 1200px) {
  .comunidade-mid-img img {
    width: 630px;
    height: 300px;
  }

  #comunidade-bottom {
    padding-bottom: 6rem;
  }
}
