.footer-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  max-width: 80%;
  width: 100%;
  height: 100%;

  padding: 2rem 0;
}

.footer-left {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.footer-left-top {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4rem;
}

.footer-left-bootom {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-container h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-nm-yellow);
}

.footer-container small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  margin-top: 3px;
}

.footer-img-lg {
  position: absolute;
  bottom: 0;
  right: -50px;
}

.footer-img-lg img {
  height: 415px;
  object-fit: contain;
}

.footer-realizacao-lg {
  display: grid;
  width: 22%;
  gap: 1rem;
}

.footer-canguru-lg,
.footer-conheca-lg,
.footer-acompanhe-lg {
  display: grid;
  width: 15%;
  gap: 1rem;
}

.footer-mail-lg {
  display: flex;
  align-items: flex-end;
  gap: 0.25rem;

  color: var(--var-color-black);
}

.footer-mail-lg small {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;

  color: var(--var-color-black);
}

.footer-logos-lg {
  display: flex;
  width: 200px;
}

.footer-logos-lg img {
  height: 50px;
  object-fit: contain;
}

.footer-links-lg li {
  padding: 0.2rem 0;
}

.footer-icons {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.footer-icons a {
  color: var(--var-color-black);
}

.copyright-container-lg {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.2rem;
  position: relative;

  color: var(--var-color-black);

  padding-top: 0.5rem;
  margin: 0 !important;
}

.copyright-container-lg:before {
  content: "";
  background: var(--var-color-black);
  position: absolute;
  top: 0.5rem;
  left: 0;
  height: 2px;
  width: 92% !important;
}
