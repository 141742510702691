.comunidades-header {
  position: relative;
  padding: 2rem 0 1rem 0;
}

.comunidades-header:after {
  content: "";
  background: #d9d9d9;
  position: absolute;
  bottom: 0;
  left: -3%;
  height: 1px;
  width: 105%;
}

.comunidades-header p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.comunidades-header a {
  color: var(--var-color-black);
}

.comunidades-content {
  display: grid;
  gap: 1.5rem;
  margin-top: 2rem;
}

.comunidades-title p,
.comunidades-nav-title p {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.comunidades-title h5,
.comunidades-nav-title h5 {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-black);
}
