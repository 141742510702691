.infos-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 2rem;

  max-width: 625px;
}

.info-card {
  display: grid;
  justify-items: center;
  gap: 0.5rem;
}

#icon-estudantes,
#icon-estados {
  padding: 1.75rem;
  border-radius: 100px;
  background-color: var(--var-color-white);
  color: var(--var-color-nm-blue);
  box-shadow: 0px 0px 50px 0px rgba(0, 157, 220, 0.15);
}

#icon-escolas {
  padding: 1.5rem 1.75rem 0.3rem 1.75rem;
  border-radius: 100px;
  background-color: var(--var-color-white);
  color: var(--var-color-nm-blue);
  box-shadow: 0px 0px 50px 0px rgba(0, 157, 220, 0.15);
}

.info-icon img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.info-txt {
  text-align: center;
  max-width: 180px;
}

.info-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;

  color: rgba(35, 31, 32, 1);
}

.info-txt h5 {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-nm-blue);
}
