.slider-container {
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
}

.slide.active {
  display: block;
}

.slide-content {
  display: grid;
  justify-items: center;
  gap: 1rem;
  height: 320px;
  padding: 20px;

  -webkit-user-select: none;
  user-select: none;
}

.slide-icon {
  width: 45px;
  height: 45px;
  background-color: var(--var-color-nm-blue);
  border-radius: 50px;
  padding: 1.5rem;

  display: grid;
  justify-content: center;
  align-content: center;
}

.icon {
  color: var(--var-color-nm-yellow);
  transform: scaleX(-1);
}

.slide-text {
  display: grid;
  gap: 1rem;
}

.slide-top {
  width: 290px;
}

.slide-top p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  text-align: center;
}

.slide-bottom {
  display: grid;
  justify-items: center;
}

.slide-bottom h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-nm-blue);
}

.slide-bottom small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.pagination-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  background-color: transparent;
  border: 3px solid var(--var-color-nm-blue);
}

.pagination-dot.active {
  background-color: transparent;
  border: 3px solid var(--var-color-nm-yellow);
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 900px) {
  .slides {
    flex-wrap: wrap;
  }

  .slide {
    flex-basis: calc(50% - 20px);
  }
}

@media screen and (min-width: 1200px) {
  .slides {
    flex-wrap: wrap;
  }

  .slide {
    flex-basis: calc(33.33% - 20px);
  }
}
