.experience-container {
  display: grid;
  justify-content: center;
  gap: 1rem;
  width: 95%;
  max-width: 1200px;
}

.experience-top {
  display: grid;
  gap: 1.5rem;
}

.experience-top h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 130%;
  font-weight: 800;

  color: #6f32ab;
}

.experience-mid {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  width: 100%;
  max-width: 1200px;
  height: 330px;
}

.experience-mid-txt {
  max-width: 650px;
}

.experience-mid-txt h5 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 150%;
  font-weight: 800;

  color: #b335e4;
}

.experience-mid-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 120%;
  font-weight: 700;

  color: #6f32ab;

  margin: 2rem 0 1rem 0;
}

.experience-mid-txt small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 120%;
  font-weight: 400;

  color: #6f6f6f;
}

.experience-mid-txt li {
  margin: 5px 0 0 2rem;
  list-style-type: disc;
}

.experience-mid-img img {
  width: 300px;
  object-fit: contain;
}

.experience-bottom p {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 130%;
  font-weight: 700;

  color: #6f32ab;

  margin-bottom: 1rem;
}

.experience-bottom small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 400;

  color: #6f6f6f;
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 900px) {
  .experience-top h4 {
    font-size: 42px;
  }

  .experience-mid-img img {
    width: 425px;
  }
}
