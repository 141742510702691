.inscricoes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.inscricoes-bg {
  position: absolute;
  top: 0;
  z-index: -1;
}

.inscricoes-header {
  margin-bottom: 2.5rem;
}

.inscricoes-header h4 {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-nm-blue);
}

.tabs {
  display: flex;
  justify-content: center;
  border-radius: 1.5rem;

  padding: 0.5rem 0.5rem;

  background-color: var(--var-color-white);
}

.tab {
  padding: 10px 30px;
  cursor: pointer;
  background-color: transparent;

  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;

  color: var(--var-color-black);
}

.tab.active {
  background-color: var(--var-color-nm-blue);
  border-radius: 1rem;

  color: var(--var-color-nm-yellow);
}

.tab-content {
  padding: 20px;
}

.cards-inscricoes {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
}

.inscricoes-txt {
  margin-top: 3rem;
}

.inscricoes-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
  line-height: 25px;
  font-weight: 800;
  text-align: center;

  color: var(--var-color-black);
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 1200px) {
  .cards-inscricoes {
    flex-flow: row nowrap;
  }

  #separador-web {
    display: unset;
  }

  #separador-mobile {
    display: none;
  }

  .separador {
    margin-top: 1rem;
  }
}
