#banner {
  box-shadow: 0px 0px 50px 0px rgba(0, 157, 220, 0.15);
  transition: all 0.5s ease-in-out;
}

.banner-container {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  position: relative;
}

.banner-txt1 {
  display: grid;
  gap: 0.5rem;
  width: 100%;
  max-width: 500px;
}

.banner-txt1 h2 {
  font-family: "Karla", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;

  background: linear-gradient(60deg, #0cb2eb 0%, #6cc266 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  max-width: 540px;

  text-transform: uppercase;
}

.banner-txt1 span {
  font-family: "Open Sans", sans-serif;
}

.banner-txt1 p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-nm-yellow);
}

.banner-txt1 small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

#negrito {
  font-weight: 800;
}

.banner-btn1 {
  cursor: pointer;
  background-color: var(--var-color-nm-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
  box-shadow: 1px 6px 6px rgba(1, 114, 163, 0.2);

  padding: 0.75rem 1.75rem !important;
}

.banner-btn1:hover {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-nm-blue);
}

.banner-btn1 a:hover {
  color: var(--var-color-white);
}

.banner-btn1:active {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
}

.banner-btn1 a:active {
  color: var(--var-color-white);
  font-weight: 600;
}

.banner-btn1 a,
.banner-btn1 p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.banner-img1 {
  display: grid;
  position: relative;
}

.banner-img1::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.banner-img1 img {
  width: 300px;
  height: 250px;
  object-fit: contain;
}

.banner-txt2 {
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 525px;
}

.banner-txt2 h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;

  background: linear-gradient(60deg, #0cb2eb 0%, #6cc266 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  max-width: 540px;
}

.banner-txt2 p {
  font-family: "Karla", sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-nm-green);
}

.banner-txt2 small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  margin-top: 1rem;
}

.banner-btn2 {
  cursor: pointer;
  background-color: var(--var-color-nm-yellow);
  border-bottom: 5px solid var(--var-color-dk-yellow);
  box-shadow: 1px 6px 6px rgba(1, 114, 163, 0.2);

  padding: 0.75rem 1.75rem !important;
}

.banner-btn2:hover {
  background-color: var(--var-color-dk-yellow);
  border-bottom: 5px solid var(--var-color-nm-yellow);
}

.banner-btn2 a:hover {
  color: var(--var-color-white);
}

.banner-btn2:active {
  background-color: var(--var-color-dk-yellow);
  border-bottom: 5px solid var(--var-color-dk-yellow);
}

.banner-btn2 a:active {
  color: var(--var-color-white);
  font-weight: 600;
}

.banner-btn2 a,
.banner-btn2 p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.banner-img2 {
  position: relative;
  display: grid;
}

.banner-img2::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.banner-img2 img {
  width: 300px;
  height: 250px;
  object-fit: contain;
}

.slide-btn1 {
  position: absolute;
  top: 50%;
  left: -10rem;

  cursor: pointer;
  color: #fff;
  background: linear-gradient(
    90deg,
    rgba(12, 178, 235, 0.5) 0%,
    rgba(108, 194, 102, 0.5) 100%
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
}

.slide-btn2 {
  position: absolute;
  top: 50%;
  right: -10rem;

  cursor: pointer;
  color: #fff;
  background: linear-gradient(
    270deg,
    rgba(12, 178, 235, 0.5) 0%,
    rgba(108, 194, 102, 0.5) 100%
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
}

.ribbon {
  position: relative;
  background-color: var(--var-color-nm-blue);
  padding: 4px;
  color: white;
  width: fit-content;
  text-align: center;
  width: 70%;
  max-width: 400px;
}

.ribbon p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-white) !important;
}

.ribbon:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 17px solid var(--var-color-nm-blue);
  border-left: 15px solid var(--var-color-white);
  border-top: 17px solid var(--var-color-nm-blue);
}

.ribbon:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  border-bottom: 17px solid var(--var-color-nm-blue);
  border-right: 15px solid var(--var-color-white);
  border-top: 17px solid var(--var-color-nm-blue);
}

.banner-txt3 {
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 570px;
}

.banner-txt3 h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;

  color: var(--var-color-nm-blue);

  max-width: 560px;
}

.banner-txt3 small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.banner-txt3 p {
  font-family: "Karla", sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-nm-green);
}

.banner-img3 {
  display: grid;
}

.banner-img3 img {
  width: 300px;
  height: 250px;
  object-fit: contain;
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 600px) {
  #banner {
    height: 350px;
  }

  .banner-container {
    flex-flow: row nowrap;
  }

  .banner-img1 img,
  .banner-img2 img,
  .banner-img3 img {
    width: 350px;
    height: 250px;
  }
}

@media screen and (min-width: 900px) {
  .banner-container {
    justify-content: space-evenly;
  }

  .banner-txt1,
  .banner-txt2,
  .banner-txt3 {
    width: 50%;
  }

  .banner-img1 img,
  .banner-img2 img,
  .banner-img3 img {
    width: 380px;
    height: 280px;
  }
}

@media screen and (min-width: 1200px) {
  #banner {
    height: 450px;
  }

  .banner-container {
    max-width: 1080px;
  }

  .banner-txt1 h2 {
    font-size: 40px;
    line-height: 120%;
  }

  .banner-txt2 h2 {
    font-size: 47px;
    line-height: 120%;
  }

  .banner-txt3 h2 {
    font-size: 35px;
    line-height: 120%;
  }

  .banner-txt1 p {
    font-size: 20px;
    line-height: 26px;
  }

  .banner-txt2 p {
    font-size: 40px;
    line-height: 26px;
  }

  .banner-txt3 p {
    font-size: 30px;
    line-height: 120%;
  }

  .banner-txt1 small {
    font-size: 20px;
    line-height: 26px;
    width: 540px;
  }

  .banner-txt2 small {
    font-size: 20px;
    line-height: 26px;
    width: 560px;
  }

  .banner-txt3 small {
    font-size: 18px;
    line-height: 28px;
    width: 560px;
  }

  .banner-btn1,
  .banner-btn2 {
    margin-top: 1rem;
  }

  .banner-btn1 a,
  .banner-btn2 a,
  .banner-btn1 p,
  .banner-btn2 p {
    font-size: 16px;
  }

  .banner-img1 img {
    width: 475px;
    height: unset;
    object-fit: contain;
  }

  .banner-img2 img {
    width: 615px;
    height: unset;
    object-fit: contain;
  }

  .banner-img3 img {
    width: unset;
    height: 400px;
    object-fit: contain;
  }

  .ribbon p {
    font-size: 22px;
  }
}
