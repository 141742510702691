.sobre-container {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.sobre-txt {
  display: grid;
  gap: 0.5rem;
  width: 100%;
  max-width: 380px;
}

.sobre-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.sobre-txt h4 {
  font-family: "Karla", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 800;

  color: var(--var-color-black);
}

.sobre-txt small {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;

  color: var(--var-color-black);

  max-width: 282px;
}

.sobre-img {
  display: grid;
}

.sobre-img img {
  width: 280px;
  height: 240px;
  object-fit: contain;
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 600px) {
  .sobre-container {
    flex-flow: row nowrap;
  }

  .sobre-img img {
    width: 350px;
    height: 250px;
  }
}

@media screen and (min-width: 900px) {
  .sobre-container {
    justify-content: space-evenly;
  }

  .sobre-img img {
    width: 380px;
    height: 280px;
  }
}

@media screen and (min-width: 1200px) {
  /* #sobre {
    margin-top: unset;
    height: 450px;
  } */

  .sobre-container {
    max-width: 1080px;
  }

  /* .sobre-txt {
    position: absolute;
    top: 4rem;
    left: 0;
    width: unset;
    width: 500px;
  } */

  .sobre-txt small {
    font-size: 16px;
    max-width: 375px;
  }

  /* .sobre-img {
    position: absolute;
    right: 0;
    top: 0;
  } */

  .sobre-img img {
    width: 450px;
    height: unset;
    object-fit: contain;
  }

  .sobre-txt p {
    font-size: 24px;
  }

  .sobre-txt h4 {
    font-size: 24px;
  }

  .sobre-txt small {
    font-size: 16px;
  }
}
