.card-combo {
  background: var(--var-color-white);
  box-shadow: 0px 0px 50px 0px rgba(0, 157, 220, 0.15);
  border-radius: 25px;
  padding: 16px;
  position: relative;
  width: 365px;
  height: 775px;
}

.semi-circle-combo {
  background-color: var(--var-color-purple);
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 25px 25px 0 0;

  display: grid;
  align-items: center;
}

.semi-circle-combo p {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 130%;
  font-weight: 700;

  color: #f2f9ff;

  text-align: center;
}

.card-combo-content {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
  margin-top: 4rem;
}

.combo-top-txt {
  display: grid;
  justify-items: center;
  margin-top: 1rem;
}

.combo-top-txt h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;

  color: var(--var-color-purple);

  text-align: center;
}

.combo-top-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 140%;
  font-weight: 700;

  color: var(--var-color-black);

  text-align: center;
  margin-top: 0.5rem;
}

.combo-mid-txt {
  display: grid;
  gap: 0.5rem;
}

.combo-item1,
.combo-item2,
.combo-item3,
.combo-item4,
.combo-item5,
.combo-item6 {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.35rem;
}

.combo-item2 img {
  position: absolute;
  transform: translateX(-50%);
  z-index: 10;
}

.combo-item2 p {
  width: 309px;
  z-index: 1;
}

.img-combo1 {
  top: 50%;
  left: 55.5%;
  width: 185px;
  object-fit: contain;
}

.img-combo2 {
  top: 50%;
  left: 43%;
  width: 190px;
  object-fit: contain;
}

.img-combo3 {
  top: 50%;
  left: 63.5%;
  width: 190px;
  object-fit: contain;
}

.img-combo4 {
  top: 50%;
  left: 44%;
  width: 200px;
  object-fit: contain;
}

.img-combo5 {
  top: 50%;
  left: 61%;
  width: 270px;
  object-fit: contain;
}

.img-combo6 {
  top: 50%;
  left: 59%;
  width: 190px;
  object-fit: contain;
}

.padrao-item2 p {
  width: 305px;
}

.padrao-item2 span {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 130%;
  font-weight: 900;

  color: var(--var-color-nm-blue);

  background-color: #ffd200;
  border-radius: 5px;
  padding: 3px 5px;
}

.combo-mid-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;

  color: var(--var-color-black);
}

.combo-item0 {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.combo-item0 p {
  color: var(--var-color-purple);
  font-weight: 800;
}

.combo-item0-main {
  align-self: center;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.combo-item0-main p {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 140%;
  font-weight: 800;

  color: var(--var-color-purple);

  text-align: center;
}

.combo-item0-txt {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.combo-item0-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 140%;
  font-weight: 800;

  color: var(--var-color-purple);
}

.combo-low-txt {
  display: grid;
  justify-items: center;
}

.combo-valor {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 0.25rem;
  width: 100%;
  max-width: 315px;
}

.combo-valor p {
  font-family: "Open Sans", sans-serif;
  font-size: 32px;
  line-height: 120%;
  font-weight: 800;

  color: var(--var-color-purple);
}

.combo-valor small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  align-self: flex-end;
}

.combo-pacote {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 2px solid var(--var-color-purple);
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
}

.combo-pacote-left {
  display: grid;
  justify-items: center;
}

.combo-pacote-left p {
  font-family: "Open Sans", sans-serif;
  font-size: 23px;
  line-height: 110%;
  font-weight: 800;

  color: var(--var-color-purple);
}

.combo-pacote-left span {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 130%;
  font-weight: 800;

  color: var(--var-color-purple);
}

.combo-pacote-left small {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 130%;
  font-weight: 800;

  color: var(--var-color-purple);
}

.combo-pacote-right p {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 130%;
  font-weight: 800;

  color: var(--var-color-purple);
}

.btn-card-combo {
  cursor: pointer;
  background-color: var(--var-color-nm-yellow);
  border-bottom: 5px solid var(--var-color-dk-yellow);
  box-shadow: 1px 6px 6px rgba(1, 114, 163, 0.2);

  margin-top: 1rem;
  padding: 0.5rem 1.5rem !important;
  align-self: center;
}

.btn-card-combo a,
.btn-card-combo p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  color: var(--var-color-black);
}

.btn-card-combo:hover {
  background-color: var(--var-color-dk-yellow);
  border-bottom: 5px solid var(--var-color-nm-yellow);
}

.btn-card-combo a:hover {
  color: var(--var-color-white);
}

.btn-card-combo:active {
  background-color: var(--var-color-dk-yellow);
  border-bottom: 5px solid var(--var-color-dk-yellow);
}

.btn-card-combo a:active {
  color: var(--var-color-white);
  font-weight: 600;
}
