#depoimentos {
  padding-bottom: 5rem;
}

.depoimentos-container {
  display: grid;
  align-items: center;
  justify-items: center;
}

.depoimentos-txt {
  display: grid;
  text-align: center;
  width: 90%;
  max-width: 560px;
}

.depoimentos-txt h3 {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-black);
}

.depoimentos-txt h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 31px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.depoimentos-carousel {
  margin-top: 1rem;
}

@media screen and (max-width: 375px) {
  .depoimentos-txt h3 {
    font-size: 16;
    line-height: 22px;
  }

  .depoimentos-txt h4 {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 600px) {
  .depoimentos-txt h3 {
    font-size: 24px;
    line-height: 30px;
  }

  .depoimentos-txt h4 {
    font-size: 16px;
    line-height: 22px;
  }
}
