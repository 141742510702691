.provas-header {
  position: relative;
  padding: 2rem 0 1rem 0;
}

.provas-header:after {
  content: "";
  background: #d9d9d9;
  position: absolute;
  bottom: 0;
  left: -3%;
  height: 1px;
  width: 105%;
}

.provas-header p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.provas-header a {
  color: var(--var-color-black);
}

.btn-corte {
  cursor: pointer;
  background-color: var(--var-color-nm-yellow);
  border-bottom: 5px solid var(--var-color-dk-yellow);
  box-shadow: 1px 6px 6px rgba(1, 114, 163, 0.2);

  margin-top: 0.5rem;
  padding: 0.5rem 1.5rem !important;
  align-self: center;
}

.btn-corte a {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  color: var(--var-color-black);
}

.btn-corte:hover {
  background-color: var(--var-color-dk-yellow);
  border-bottom: 5px solid var(--var-color-nm-yellow);
}

.btn-corte a:hover {
  color: var(--var-color-white);
}

.btn-corte:active {
  background-color: var(--var-color-dk-yellow);
  border-bottom: 5px solid var(--var-color-dk-yellow);
}

.btn-corte a:active {
  color: var(--var-color-white);
  font-weight: 600;
}

.provas-content {
  display: grid;
  gap: 1.5rem;
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.provas-title p {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.provas-title h5 {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-black);
}

.provas-recente {
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
}

.provas-anteriores {
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
}

.provas-recente p {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-nm-blue);
}

.provas-anteriores p {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-nm-green);
}

.provas-anteriores-buttons {
  display: flex;
  gap: 1rem;
}

.btn-provas-recente,
.btn-provas-2023,
.btn-provas-2022,
.btn-provas-2021 {
  padding: 0.5rem 1.5rem !important;
}

.btn-provas-recente a,
.btn-provas-2023 a,
.btn-provas-2022 a,
.btn-provas-2021 a {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  color: var(--var-color-black);
}

.btn-provas-recente {
  cursor: pointer;
  background-color: var(--var-color-nm-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
  box-shadow: 1px 6px 6px rgba(1, 114, 163, 0.2);
}

.btn-provas-recente:hover {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-nm-blue);
}

.btn-provas-recente a:hover {
  color: var(--var-color-white);
}

.btn-provas-recente:active {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
}

.btn-provas-recente a:active {
  color: var(--var-color-white);
  font-weight: 600;
}

.btn-provas-2023,
.btn-provas-2022,
.btn-provas-2021 {
  cursor: pointer;
  background-color: var(--var-color-nm-green);
  border-bottom: 5px solid var(--var-color-dk-green);
  box-shadow: 1px 6px 6px rgba(55, 136, 49, 0.2);
}

.btn-provas-2023:hover,
.btn-provas-2022:hover,
.btn-provas-2021:hover {
  background-color: var(--var-color-dk-green);
  border-bottom: 5px solid var(--var-color-nm-green);
}

.btn-provas-2023 a:hover,
.btn-provas-2022 a:hover,
.btn-provas-2021 a:hover {
  color: var(--var-color-white);
}

.btn-provas-2023:active,
.btn-provas-2022:active,
.btn-provas-2021:active {
  background-color: var(--var-color-dk-green);
  border-bottom: 5px solid var(--var-color-dk-green);
}

.btn-provas-2023 a:active,
.btn-provas-2022 a:active,
.btn-provas-2021 a:active {
  color: var(--var-color-white);
  font-weight: 600;
}

/* CSS PÁGINAS DE PROVAS */
.provas-p,
.provas-e,
.provas-b,
.provas-c,
.provas-j,
.provas-s {
  display: grid;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #d9d9d9;
}

.provas-txt {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.provas2023-buttons p,
.provas2022-buttons p,
.provas2021-buttons p {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 34px;

  color: var(--var-color-nm-blue);
}

.prova-buttons {
  display: flex;
  gap: 1rem;
}

.provas-txt small {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 140%;

  color: var(--var-color-nm-blue);
}

.btn-prova,
.btn-prova-ingles,
.btn-gabarito {
  padding: 0.33rem 1.5rem !important;
}

.btn-prova a,
.btn-prova-ingles a,
.btn-gabarito a {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  color: var(--var-color-black);
}

.btn-prova,
.btn-prova-ingles {
  cursor: pointer;
  background-color: var(--var-color-nm-green);
  border-bottom: 5px solid var(--var-color-dk-green);
  box-shadow: 1px 6px 6px rgba(55, 136, 49, 0.2);
}

.btn-prova:hover,
.btn-prova-ingles:hover {
  background-color: var(--var-color-dk-green);
  border-bottom: 5px solid var(--var-color-nm-green);
}

.btn-prova a:hover,
.btn-prova-ingles a:hover {
  color: var(--var-color-white);
}

.btn-prova:active,
.btn-prova-ingles:active {
  background-color: var(--var-color-dk-green);
  border-bottom: 5px solid var(--var-color-dk-green);
}

.btn-prova a:active,
.btn-prova-ingles a:active {
  color: var(--var-color-white);
  font-weight: 600;
}

.btn-gabarito {
  cursor: pointer;
  background-color: var(--var-color-nm-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
  box-shadow: 1px 6px 6px rgba(1, 114, 163, 0.2);
}

.btn-gabarito:hover {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-nm-blue);
}

.btn-gabarito a:hover {
  color: var(--var-color-white);
}

.btn-gabarito:active {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
}

.btn-gabarito a:active {
  color: var(--var-color-white);
  font-weight: 600;
}
