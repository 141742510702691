.regulamento-txt {
  margin-top: 2rem;
  max-width: 1080px;
  width: 100%;
  padding-bottom: 5rem;
}

#regulamento {
  display: grid;
  gap: 1rem;
}

#list li {
  display: flex;
  gap: 0.3rem;
}

#list-dificuldade {
  margin-left: 0.5rem;
}

#list-dificuldade li {
  margin-left: 1rem;
  margin-bottom: 0.3rem;
}

.regulamento-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-black);
}

.regulamento-txt small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.regulamento-txt a {
  text-decoration: none;
  color: var(--var-color-black);
}
