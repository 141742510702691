@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

/* font-family: 'Karla', sans-serif;
font-family: 'Open Sans', sans-serif; */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --var-color-white: #fff;
  --var-color-black: #231f20;
  --var-color-gray: #626161;

  --var-color-nm-blue: #009ddc;
  --var-color-dk-blue: #0172a3;
  --var-color-nm-green: #49a942;
  --var-color-dk-green: #378831;
  --var-color-nm-yellow: #ffd200;
  --var-color-dk-yellow: #d19a00;
  --var-color-purple: #642ee4;

  --var-color-box-bg: rgba(255, 210, 0, 0.2);

  --container-width-lg: 82%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background: var(--var-color-white);
  color: var(--var-color-black);
  width: 100%;
}

/* --- GENERAL STYLES --- */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

section {
  margin-top: 6rem;
}

.btn {
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 5rem;
  /* border: 1px solid var(--var-color-black); */
}

/* .btn:hover {
  box-shadow: 0px 5px #141414;
} */

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* --- MEDIA QUERIES --- */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section {
    margin-top: 2rem;
  }
}
