.cronograma-header {
  position: relative;
  padding: 2rem 0 1rem 0;
}

.cronograma-header:after {
  content: "";
  background: #d9d9d9;
  position: absolute;
  bottom: 0;
  left: -3%;
  height: 1px;
  width: 105%;
}

.cronograma-header p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.cronograma-header a {
  color: var(--var-color-black);
}

.cronograma-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 2rem;
  padding-bottom: 4rem;
}

.cronograma-title p {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;

  color: var(--var-color-nm-blue);
}

.cronograma-title h5 {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-black);
}

.cronograma-web {
  display: none;
}

.cronograma-mobile {
  display: grid;
  gap: 2rem;
}

.presencial-mobile,
.online-mobile {
  display: grid;
  justify-items: center;
  gap: 1rem;
}

/* --- MEDIA QUERIES --- */
@media screen and (min-width: 1024px) {
  .cronograma-mobile {
    display: none;
  }

  .cronograma-web {
    align-self: center;
    display: unset;
  }

  .cronograma-web img {
    width: 450px;
    object-fit: contain;
  }
}

@media screen and (min-width: 1200px) {
  .cronograma-web img {
    width: 550;
  }
}
