.card-padrao {
  background: var(--var-color-white);
  box-shadow: 0px 0px 50px 0px rgba(0, 157, 220, 0.15);
  border-radius: 25px;
  padding: 16px;
  position: relative;
  width: 365px;
  height: 645px;
}

.semi-circle-padrao {
  background-color: var(--var-color-nm-blue);
  width: 180px;
  height: 180px;
  position: absolute;
  top: 0px;
  left: calc(40% - 50px);
  clip-path: ellipse(35% 20% at 50% -5%);
}

.card-padrao-content {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
}

.padrao-top-txt {
  display: grid;
  justify-items: center;
  margin-top: 1rem;
}

.padrao-top-txt h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;

  color: var(--var-color-nm-blue);
}

.padrao-top-txt small {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.padrao-top-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;

  color: var(--var-color-nm-blue);

  margin-top: 1rem;
  text-align: center;
}

.padrao-mid-txt {
  display: grid;
  justify-content: start;
  gap: 0.5rem;
  margin-top: 1rem;
}

.padrao-item1,
.padrao-item2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.padrao-item2 {
  position: relative;
}

.padrao-item2 p {
  width: 300px;
  z-index: 1;
}

.padrao-item2 img {
  position: absolute;
  transform: translateX(-50%);
  z-index: 10;
}

.img-padrao1 {
  top: 50%;
  left: 84%;
  width: 175px;
  object-fit: contain;
}

.img-padrao2 {
  top: 50%;
  left: 76%;
  width: 180px;
  object-fit: contain;
}

.img-padrao3 {
  top: 50%;
  left: 61.5%;
  width: 200px;
  object-fit: contain;
}

.img-padrao4 {
  top: 50%;
  left: 68.5%;
  width: 205px;
  object-fit: contain;
}

.padrao-item3 {
  display: grid;
  justify-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dbdbdb;
}

.item3-top {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
  width: 100%;
}

.item3-low {
  width: 100%;
  max-width: 285px;
  margin-left: 30px;
}

.item3-low small {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;

  color: var(--var-color-black);
}

.padrao-mid-txt p {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;

  color: var(--var-color-black);
}

.padrao-low-txt {
  margin-top: 3rem;
}

.valor-padrao-solo {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  max-width: 305px;
  padding-bottom: 0.25rem;
}

.valor-padrao-riscado {
  align-self: flex-end;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);

  text-decoration: line-through;
}

.destaque-valor-padrao-solo {
  display: flex;
  justify-content: space-between;
  max-width: 305px;
}

.valor-padrao-solo p {
  font-family: "Karla", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;

  color: var(--var-color-nm-blue);
}

.valor-padrao-desconto {
  align-self: center;

  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

.boleto-valor-padrao-solo {
  display: flex;
  justify-content: space-between;
  max-width: 305px;
  margin-top: 1rem;
}

.boleto-valor-padrao-solo p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  color: var(--var-color-black);
}

#left-txt {
  max-width: 170px;
}

.btn-card-padrao {
  cursor: pointer;
  background-color: var(--var-color-nm-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
  box-shadow: 1px 6px 6px rgba(1, 114, 163, 0.2);

  margin-top: 0.5rem;
  padding: 0.5rem 1.5rem !important;
  align-self: center;
}

.btn-card-padrao a,
.btn-card-padrao p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  color: var(--var-color-black);
}

.btn-card-padrao:hover {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-nm-blue);
}

.btn-card-padrao a:hover {
  color: var(--var-color-white);
}

.btn-card-padrao:active {
  background-color: var(--var-color-dk-blue);
  border-bottom: 5px solid var(--var-color-dk-blue);
}

.btn-card-padrao a:active {
  color: var(--var-color-white);
  font-weight: 600;
}
